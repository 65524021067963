.draggable-card {
  padding: 10px;
  box-sizing: border-box;
}

.draggable-box {
  display: flex;
  justify-content: space-between;
}

.draggable-button {
  padding: 0;
}
